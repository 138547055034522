import { translate } from "@/core/helpers/translate";
import TimeAgo from 'javascript-time-ago'
import id from 'javascript-time-ago/locale/id'
import { ElLoading } from 'element-plus'

const makeSureTwoDigit = (value: number) => {
  const valueString = value.toString();
  return valueString.length === 2 ? valueString : `0${value}`;
};

TimeAgo.addDefaultLocale(id)
const _timeAgo = new TimeAgo('id-ID')
const timeAgo = (date: Date) => {
  return _timeAgo.format(date)
}

const getMonthLabel = month => {
  let result = "";
  if (month === 1) {
    result = translate("january");
  } else if (month === 2) {
    result = translate("february");
  } else if (month === 3) {
    result = translate("march");
  } else if (month === 4) {
    result = translate("april");
  } else if (month === 5) {
    result = translate("may");
  } else if (month === 6) {
    result = translate("june");
  } else if (month === 7) {
    result = translate("july");
  } else if (month === 8) {
    result = translate("august");
  } else if (month === 9) {
    result = translate("september");
  } else if (month === 10) {
    result = translate("october");
  } else if (month === 11) {
    result = translate("november");
  } else if (month === 12) {
    result = translate("december");
  }

  return result;
};

const getDayLabel = day => {
  let label = "";
  switch (day) {
    case 1:         
      label =  translate('monday')
      break;  
    case 2:         
      label =  translate('tuesday')
      break; 
    case 3:         
      label =  translate('wednesday')
      break; 
    case 4:         
      label =  translate('thursday')
      break; 
    case 5:         
      label =  translate('friday')
      break; 
    case 6:         
      label =  translate('saturday')
      break; 
    default:
      label =  translate('sunday' )
      break;
  }

  return label;
};

const convertToFullDate = date => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${getMonthLabel(month)} ${days}, ${year}`;
};

const convertToFullDateAndTime = date => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);
  const second = ('0'+(d.getSeconds())).slice(-2);

  return `${getMonthLabel(month)} ${days}, ${year} - ${hour}:${minute}`;
};

const convertToFullDateDay = date => {
  const d = new Date(date);
  const dayName = getDayLabel(d.getDay());
  const day = ('0'+(d.getDate())).slice(-2);;
  const month = ('0'+(d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${dayName}, ${days}-${month}-${year}`;
};

const convertToFullDateDayAndHourMinute = date => {
  const d = new Date(date);
  const dayName = getDayLabel(d.getDay());
  const day = ('0'+(d.getDate())).slice(-2);;
  const month = ('0'+(d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);

  return `${dayName}, ${days}-${month}-${year} ${hour}:${minute}`;
};

const convertToDate = date => {
  const d = new Date(date);
  const dayName = getDayLabel(d.getDay());
  const day = ('0'+(d.getDate())).slice(-2);;
  const month = ('0'+(d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${days}-${month}-${year}`;
};

const convertToDateValue = date => {
  const d = new Date(date);
  const dayName = getDayLabel(d.getDay());
  const day = ('0'+(d.getDate())).slice(-2);;
  const month = ('0'+(d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${year}-${month}-${days}`;
};

const convertToDateTimeValue = date => {
  const d = new Date(date);
  const day = ('0'+(d.getDate())).slice(-2);;
  const month = ('0'+(d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);
  const second = ('0'+(d.getSeconds())).slice(-2);

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${year}-${month}-${days} ${hour}:${minute}:${second}`;
};

const convertToDay = date => {
  const d = new Date(date);
  const dayName = getDayLabel(d.getDay());

  return `${dayName}`;
};

const convertToFullDateTime = date => {
  const d = new Date(date);
  const day = ('0'+(d.getDate())).slice(-2);
  const month = ('0'+(d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);
  const second = ('0'+(d.getSeconds())).slice(-2);

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${days}-${month}-${year} ${hour}:${minute}:${second}`;
};

const convertToFullDateTimeWithoutSecond = date => {
  const d = new Date(date);
  const day = ('0'+(d.getDate())).slice(-2);
  const month = ('0'+(d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear();

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);
  const second = ('0'+(d.getSeconds())).slice(-2);

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${days}-${month}-${year} ${hour}:${minute}`;
};

const convertToFullDateTimeWithMonthLabel = date => {
  const d = new Date(date);
  const day = ('0'+(d.getDate())).slice(-2);
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);
  const second = ('0'+(d.getSeconds())).slice(-2);

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  return `${days} ${getMonthLabel(month)} ${year} ${hour}:${minute}:${second}`;
};

const convertToFullDateTimeWithLabel = date => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  let days = "";
  if (day.toString().length == 1) {
    days = "0" + day;
  } else {
    days = day.toString();
  }

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);
  const second = ('0'+(d.getSeconds())).slice(-2);

  return `${days} ${getMonthLabel(month)} ${year} ${translate('clock')} ${hour}:${minute}`;
};

const convertToHourMinuteOnly = date => {
  const d = new Date(date);

  const hour = ('0'+(d.getHours())).slice(-2);
  const minute = ('0'+(d.getMinutes())).slice(-2);

  return `${hour}:${minute}`;
};

const getCurrentWeek = () => {
  const d = new Date();
  const date = d.getDate();
  const day = d.getDay();

  const weekOfMonth = Math.ceil((date + 6 - day) / 7);
  return weekOfMonth;
};

function weekCount(year, month) {
  // month is in the range 1..12
  const firstOfMonth = new Date(year, month-1, 1);
  const lastOfMonth = new Date(year, month, 0);

  const used = firstOfMonth.getDay() + lastOfMonth.getDate();

  return Math.ceil( used / 7);
}

export function loadingBlock() {
  return ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}

export function loadingUnBlock() {
  setTimeout(() => {
    ElLoading.service().close()
  }, 300)
}

export { 
  makeSureTwoDigit,
  timeAgo,
  convertToFullDate,
  convertToFullDateDay,
  convertToFullDateDayAndHourMinute,
  convertToDay,
  convertToDate,
  convertToDateValue,
  convertToDateTimeValue,
  convertToFullDateTime,
  convertToFullDateAndTime,
  getMonthLabel,
  convertToFullDateTimeWithLabel,
  convertToFullDateTimeWithMonthLabel,
  convertToFullDateTimeWithoutSecond,
  convertToHourMinuteOnly,
  getCurrentWeek,
  weekCount
};
